<template>
    <div class="vap-page">
      <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>
        <VasionInput
          id="new-AIP-title"
          v-model="AIPName"
          name="new-AIP-title"
          class="input-style"
          inputType="title-input"
          placeholder="Untitled Process *"
          :isTitleInput="true"
          @input="markAsDirty"
        />
      </h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          name="save-button"
          classProp="primary"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>

        <VasionButton
          id="cancel-button"
          class="last-btn"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="aip-details">
        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedForm"
            class="row-field"
            :dataArray="indexForms"
            :width="'310'"
            :title="`${$formsLabel.toUpperCase()}`"
            type="plain-list"
            displayName="name"
            valueName="value"
            :required="true"
            @input="markAsDirty(); changeSelectedForm();"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionButton
            id="browse-button"
            class="browse-button"
            :classProp="'primary'"
            :required="true"
            label="Template File"
            @vasionButtonClicked="toggleUploadDialog"
          >
            {{ browseButtonLabel }}
          </VasionButton>
        </div>
        <div class="row">
          <VasionCheckbox
            id="run-lookup"
            name="run-lookup"
            class="extra-top-margin checkbox row-field"
            :checked="runLookup"
            @change="markAsDirty(); toggleRunLookup()"
          >
            Run Lookup After Process
          </VasionCheckbox>
          <div class="row-field">
            <VasionButton
              id="static-fields"
              name="static-fields"
              class="configure-template"
              :classProp="'secondary'"
              :isDisabled="selectedObjectID <= 0"
              @vasionButtonClicked="showStaticFieldsModal = true"
            >
              STATIC FIELDS
            </VasionButton>
            <VasionButton
              id="view-zones"
              name="view-zones"
              class="configure-template"
              :classProp="'secondary'"
              :isDisabled="!disableViewZones"
              @vasionButtonClicked="viewZones()"
            >
              VIEW ZONES
            </VasionButton>
          </div>
        </div>
        <div class="row">
          <div class="tab-div">
            <VasionButton id="tab-processing-checks" :classProp="calculatedClass('processingChecks')" @vasionButtonClicked="updateSelectedTab('processingChecks')">
              Processing Checks
            </VasionButton>
            <VasionButton id="tab-zonal-processing" :classProp="calculatedClass('zonalProcessing')" @vasionButtonClicked="updateSelectedTab('zonalProcessing')">
              Zonal Processing
            </VasionButton>
          </div>
        </div>
        <div v-show="selectedTabName === 'processingChecks'" id="processing-checks-tab" class="top-padding">
          <div class="row">
            <VasionCheckbox
              id="rotate-zone-for-check"
              name="rotate-zone-for-check"
              class="extra-top-margin checkbox row-field"
              :checked="rotateZonesForCheck"
              @change="markAsDirty(); toggleRotateZonesForCheck()"
            >
              Rotate Zone to Check for Matching
            </VasionCheckbox>
            <VasionButton
              id="compare-fields"
              name="compare-fields"
              class="configure-template row-field"
              :classProp="'secondary'"
              :isDisabled="Object.keys(selectedForm).length === 0"
              @vasionButtonClicked="openCompareFieldsDialog()"
            >
              COMPARE FIELDS
            </VasionButton>
          </div>
          <div id="processing-check-list" class="vasion-html-table-default">
            <table>
              <thead>
                <tr>
                  <th>
                    <label class="vasion-html-table-header">Name</label>
                  </th>
                  <th>
                    <label class="vasion-html-table-header">Text Compare</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-header">Compare Value</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(check, index) in processingChecks"
                  :key="index"
                  :class="{'lightestGreyBackground': index % 2 === 0 }"
                >
                  <td>
                    <label class="vasion-html-table-field">{{ check.checkName }}</label>
                  </td>
                  <td>
                    <label class="vasion-html-table-field pointer">{{ check.compareValue }}</label>
                  </td>
                  <td>
                    <label class="vasion-html-table-field pointer">{{ check.textCompare }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="processingChecks.length === 0" class="no-items-div">
              <VasionEmptyProcessingChecks />
              <div class="vasion-page-subheader instructional-message-div">
                There are no processing checks
              </div>
              <div class="vasion-page-subheader instructional-message-div">
                Click "View Zones" to add Zones to your Document
              </div>
            </div>
          </div>
        </div>
        <div v-show="selectedTabName === 'zonalProcessing'" id="zonal-processing-tab">
          <div id="processing-check-list" class="vasion-html-table-default">
            <table>
              <thead>
                <tr>
                  <th>
                    <label class="vasion-html-table-header">Name</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-header">Type</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(process, index) in zonalProcesses"
                  :key="index"
                  :class="{'lightestGreyBackground': index % 2 === 0 }"
                >
                  <td>
                    <label class="vasion-html-table-field">{{ process.name }}</label>
                  </td>
                  <td>
                    <label class="vasion-html-table-field pointer">{{ zonalProcessingType(process) }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="zonalProcesses.length === 0" class="no-items-div">
              <VasionZonalProcessingIcon />
              <div class="vasion-page-subheader instructional-message-div">
                There are no Zonal Processes
              </div>
              <div class="vasion-page-subheader instructional-message-div">
                Click "Manage Zones" to add Zones to your Document
              </div>
            </div>
          </div>
        </div>
        <md-dialog id="upload-new-file-dialog" class="dialog" :md-active.sync="showUploadDialog" :md-click-outside-to-close="false">
          <div class="upload-container">
            <div class="file-pond-block-wrapper">
              <file-pond
                ref="pond"
                class="new-document"
                :server="serverOptions"
                :label-idle="filePondLabel"
              />
            </div>
          </div>
          <div class="align-right">
            <VasionButton
              id="close-upload-dialog"
              :isFullWidth="false"
              :classProp="'secondary'"
              @vasionButtonClicked="toggleUploadDialog"
            >
              CLOSE
            </VasionButton>
          </div>
        </md-dialog>
        <md-dialog id="compare-fields-dialog" class="dialog" :md-active.sync="showCompareFieldsDialog" :md-click-outside-to-close="false">
          <div class="compare-fields-container">
            <h3>Compare Fields</h3>
            <h5>Only run the advanced image process if the following field criteria is met</h5>
          </div>
          <div id="field-list" class="compare-fields-grid-div vasion-html-table-default">
            <table>
              <thead>
                <tr>
                  <th class="field-name">
                    <label class="vasion-html-table-header">Field</label>
                  </th>
                  <th>
                    <label class="vasion-html-table-header">Compare</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-header">Value</label>
                  </th>
                </tr>
              </thead>
              <tbody class="scrollTBody">
                <tr v-for="(field, index) in compareFields" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
                  <td class="field-name padding">
                    <label class="vasion-html-table-field">{{ field.displayName }}</label>
                  </td>
                  <td class="vasion-html-table-field padding field-compare ">
                    <VasionDropList
                      v-slot="slotItem"
                      v-model="field.compareType"
                      :dataArray="compareFieldTypeList"
                      type="plain-list"
                      width="100%"
                      displayName="name"
                      valueName="value"
                      @input="markCompareFieldsAsDirty"
                      class="slight-top-padding"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                  </td>
                  <td class="field-type-column vasion-html-table-field padding">
                    <VasionInput
                      v-model="field.value"
                      inputType="top-white"
                      placeholder="Enter Field Value..."
                      @input="markCompareFieldsAsDirty"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="align-right">
            <VasionButton
              id="compare-fields-cancel-button"
              class="add-cancel"
              name="compare-fields-cancel-button"
              classProp="secondary"
              @vasionButtonClicked="cancelCompareFieldsDialog()"
            >
              Cancel
            </VasionButton>

            <VasionButton
              id="compare-fields-save-button"
              class="add-cancel"
              name="compare-fields-save-button"
              classProp="primary"
              @vasionButtonClicked="okCompareFieldsDialog()"
            >
              OK
            </VasionButton>
          </div>
        </md-dialog>
      </div>
    </div>

    <VasionGeneralModal
      id="cancel-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'Your changes have not been saved, are you sure you want to cancel?'"
      :sync="showCancelModal"
      @confirmButtonClick="cancelModalOKClick()"
      @noButtonClick="cancelModalCancelClick()"
    />

    <VasionGeneralModal
      id="change-object-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="`One or more fields from this ${$formsLabel} are in use. Are you sure you want to change ${$formsLabelPlural}?`"
      :sync="showChangeObjectsModal"
      @confirmButtonClick="changeObjectModalOKClick()"
      @noButtonClick="changeObjectModalCancelClick()"
    />

    <VasionStaticFields
      :objectID="selectedObjectID"
      :initialValues="staticFields"
      :sync="showStaticFieldsModal"
      @closeModal="staticFieldsUpdated"
    />

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

import { toBase64 } from '@/store/helperModules/storage.module'

export default {
  name: 'EditAttributeAIP',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.saving && to.name !== 'TheLogin' && to.name !== 'AipZoneConfiguration') {
      this.routeTo = to
      this.showCancelModal = true
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      aipID: 0,
      AIPName: '',
      compareFields: [],
      compareFieldsIsDirty: false,
      compareFieldTypeList: [{name: "", value: 0}],
      creatingNewAIP: false,
      fileDataString: '',
      indexForms: [],
      isDirty: false,
      isDocumentLoaded: false,
      isLoading: false,
      loadedCompareFields: [],
      loaderBackgroundColor,
      loaderColor,
      newUploadedFile: '',
      previousSelectedForm: {},
      processingChecks: [],
      rotateZonesForCheck: false,
      runLookup: false,
      savedCompareFields: [],
      saving: false,
      selectedForm: {},
      selectedItem: '',
      selectedTabName: 'processingChecks',
      serverOptions: { process: this.processHandler },
      showCancelModal: false,
      showChangeObjectsModal: false,
      showCompareFieldsDialog: false,
      showLeaveDialog: false,
      showSnackbarBool: false,
      showStaticFieldsModal: false,
      showUploadDialog: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      staticFields: [],
      template: '',
      tempTemplateFileServerPath: '',
      textAndBarcodeZones: [],
      zonalProcesses: [],
    }
  },
  computed: {
    browseButtonLabel() { return this.hasTemplateFile ? 'Change Template File...' : 'Upload Template File...' },
    disableViewZones() { return this.hasTemplateFile && Object.keys(this.selectedForm).length !== 0 },
    filePondLabel() {
      return `<span class="filepond-drag">Drag TIFF or PDF File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    hasTemplateFile() { return (this.template && this.fileDataString) || this.tempTemplateFileServerPath !== '' },
    indexFields() { return this.$store.state.common.indexFields },
    selectedObjectID() { return this.selectedForm && this.selectedForm.value !== undefined ? this.selectedForm.value : 0 },
  },
  watch: {
    selectedForm() {
      const formID = this.selectedForm && this.selectedForm.value ? this.selectedForm.value : 0
      this.$store.dispatch('capture/setSelectedFormId', formID)
    },
  },
  created: async function () {
    this.isLoading = true
    const promises = await Promise.all([
      this.$store.dispatch('capture/getAIPCompareFieldTypeList'),
      this.$store.dispatch('attributeForm/getForms'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.compareFieldTypeList = []
    promises[0].forEach((fieldType, i) => this.compareFieldTypeList.push({name: fieldType, value: i}))
    // eslint-disable-next-line prefer-destructuring
    this.indexForms = promises[1]

    this.indexForms.unshift({
      name: '',
      value: 0,
    })
    if (this.$store.state.capture.currentAIP) {
      this.getDetails(this.$store.state.capture.currentAIP)
      this.isDirty = true
    } else if (this.$route.params.AIPID > 0) {
      const response = await this.$store.dispatch('capture/getAIP', this.$route.params.AIPID)
      this.getDetails(response)
    } else {
      this.creatingNewAIP = true
      this.$store.dispatch('capture/setTempFile', '')
    }

    this.$store.dispatch('capture/setAipCheckZoneArray', null)
    this.$store.dispatch('capture/setAipTextZoneArray', null)
    this.$store.dispatch('capture/setCurrentAIP', null)
    this.saving = false
    this.isLoading = false
  },
  methods: {
    calculatedClass(selectedButton) { return this.selectedTabName === selectedButton ? 'text-selected' : 'text' },
    cancel: function () {
      if (this.isDirty) {
        this.showCancelModal = true
      } else {
        this.$router.push({ name: 'AdvancedImageProcessing' })
      }
    },
    cancelCompareFieldsDialog: function () {
      this.compareFields = this.savedCompareFields
      this.showCompareFieldsDialog = false
    },
    cancelLeave() { this.routeTo = null },
    cancelModalCancelClick() { this.showCancelModal = false },
    cancelModalOKClick: async function () {
      this.showCancelModal = false
      this.markAsClean()

      if (this.routeTo && this.routeTo.name !== '') {
        if (this.routeTo.params) {
          this.$router.push({ name: this.routeTo.name })
        } else {
          this.$router.push({ name: this.routeTo.name, params: this.routTo.params })
        }
      } else {
        this.$router.push({ name: 'AdvancedImageProcessing' })
      }
    },
    changeObjectModalCancelClick() {
      this.showChangeObjectsModal = false
      this.selectedForm = this.previousSelectedForm
    },
    changeObjectModalOKClick() {
      this.showChangeObjectsModal = false
      this.previousSelectedForm = { ...this.selectedForm }
      this.staticFields = []
      this.zonalProcesses = this.zonalProcesses.filter(z => {
        z.indexFieldName = ''
        return z
      })
      this.getFieldList()
    },
    changeSelectedForm() {
      if (Object.keys(this.previousSelectedForm).length > 0 && (this.compareFieldsIsDirty || this.staticFields.length > 0)) {
        this.showChangeObjectsModal = true
      } else {
        this.previousSelectedForm = { ...this.selectedForm }
        this.getFieldList()
      }
    },
    clickedOutsideLeave() { this.routeTo = null },
    getDetails(AIP) {
      this.aipID = AIP.aipID
      this.selectedForm = { value: AIP.objectID, name: AIP.objectName }
      this.previousSelectedForm = { value: AIP.objectID, name: AIP.objectName }
      this.AIPName = AIP.name
      this.template = AIP.tempTemplateFileServerPath.replace(/^.*[\\/]/, '')
      this.fileDataString = ''
      this.tempTemplateFileServerPath = AIP.tempTemplateFileServerPath
      this.$store.dispatch('capture/setTempFile', this.tempTemplateFileServerPath)

      this.runLookup = AIP.runLookup
      this.rotateZonesForCheck = AIP.rotateZonesForCheck
      this.loadedCompareFields = AIP.compareFields

      this.staticFields = !AIP.staticFields ? [] : AIP.staticFields.map(element => {
        const newElement = {
          fieldName: element.fieldName,
          value: element.value,
        }

        return newElement
      })

      this.staticFields = this.staticFields.filter((element) => {
        return element.value !== ''
      })

      this.getFieldList()

      if (this.$store.state.capture.currentAIP) {
        // copy the array to the local variable so we can manipulate it as needed
        this.processingChecks = JSON.parse(JSON.stringify(this.$store.state.capture.aipCheckZoneArray))
        this.textAndBarcodeZones = JSON.parse(JSON.stringify(this.$store.state.capture.aipTextZoneArray))
        this.omrZones = JSON.parse(JSON.stringify(this.$store.state.capture.aipOmrZoneArray))
        this.zonalProcesses.push(...this.textAndBarcodeZones, ...this.omrZones)
      } else {
        this.processingChecks = AIP.processingCheckZones
        this.textAndBarcodeZones = AIP.textAndBarcodeZones
        this.omrZones = AIP.omrProcessingZones

        this.zonalProcesses.push(...this.textAndBarcodeZones, ...this.omrZones)
      }
    },
    async getFieldList() {
      if (this.selectedForm.name !== '' && this.selectedForm.value > 0) {
        await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedForm.value)
        this.compareFieldsIsDirty = false

        this.compareFields = this.indexFields.map(item => {
          if (this.loadedCompareFields.length > 0) {
            let foundIndex = this.compareFieldTypeList.findIndex(field => field.value === item.value)
            const foundLoaded = this.loadedCompareFields.find(field => field.fieldName === item.value)
            if (foundLoaded !== undefined) {
              this.compareFieldsIsDirty = true
              return {
                displayName: item.name,
                fieldName: item.value,
                compareType: {name: foundLoaded.compareType, value: foundIndex},
                value: foundLoaded.value,
              }
            } else {
              return {
                displayName: item.name,
                fieldName: item.value,
                compareType: {name: '', value: 0},
                value: '',
              }
            }
          } else {
            return {
              displayName: item.name,
              fieldName: item.value,
              compareType: {name: '', value: 0},
              value: '',
            }
          }
        })
      }
    },
    getSavePayload() {
      let payload = {
        aipID: this.aipID,
        objectID: this.selectedForm.value,
        objectName: this.selectedForm.name,
        name: this.AIPName,
        sampleFileName: (this.tempTemplateFileServerPath === '') ? this.template : '',
        sampleFileBase64: (this.tempTemplateFileServerPath === '') ? this.fileDataString : '',
        tempTemplateFileServerPath: this.tempTemplateFileServerPath,
        runLookup: this.runLookup,
        rotateZonesForCheck: this.rotateZonesForCheck,
        staticFields: this.staticFields,
        processingCheckZones: this.processingChecks,
        omrProcessingZones: this.omrZones,
        textAndBarcodeZones: this.textAndBarcodeZones,
      }

      payload.compareFields = []
      this.compareFields.forEach(element => {
        if (element.compareType.name !== ''){
          let newElement = element
          newElement.compareType = element.compareType.name
          payload.compareFields.push(newElement)
        }
      })

      return payload
    },
    markAsClean() { this.isDirty = false },
    markAsDirty() { this.isDirty = true },
    markCompareFieldsAsClean() { this.compareFieldsIsDirty = false },
    markCompareFieldsAsDirty() {
      this.isDirty = true
      this.compareFieldsIsDirty = true
    },
    okCompareFieldsDialog: function () {
      if (this.validateCompareFields()) {
        this.showCompareFieldsDialog = false
      }
    },
    openCompareFieldsDialog: function () {
      this.savedCompareFields = this.compareFields.map(a => ({ ...a }))
      this.showCompareFieldsDialog = true
    },
    // eslint-disable-next-line consistent-return
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        if (!file.type.includes('tif')
          && !file.type.includes('pdf')) {
          this.snackbarTitle = 'Upload Failed'
          this.snackbarSubTitle = 'Template File only supports these file types: .tif, .tiff, .pdf'
          this.snackbarImage = false
          this.showSnackbarBool = true

          this.isDocumentLoaded = true
          // The set timeout acts as a way to reset the upload display
          setTimeout(() => { this.isDocumentLoaded = false }, 500);
        } else {
          this.template = file.name
          this.fileDataString = await toBase64(file)
          this.tempTemplateFileServerPath = ''
          const indexOf = this.fileDataString.indexOf(';base64,')
          if (indexOf >= 0) {
            this.fileDataString = this.fileDataString.substring(indexOf + 8)
          }

          progress(true, 0, 1024);
          load(file.name)

          const payload = {
            name: this.template,
            fileData: this.fileDataString,
          }
          await this.$store.dispatch('capture/uploadTempFile', payload)

          this.fileDataString = ''
          this.tempTemplateFileServerPath = this.$store.state.capture.tempFile
          this.isDocumentLoaded = true
          this.isDirty = true
          this.toggleUploadDialog();
        }

        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    save: async function () {
      if (!this.validateSaveData()) {
        return
      }

      this.isLoading = true

      let validData = true
      if (this.AIPName.trim() === '') {
        validData = false
        this.isLoading = false
        this.snackbarTitle = 'Unable to Save'
        this.snackbarSubTitle = 'AIP Name cannot only include spaces.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }

      if (validData) {
        this.saving = true
        const payload = this.getSavePayload()
        const value = await this.$store.dispatch('capture/saveAIP', payload)

        this.isLoading = false

        if (value === 0) {
          this.showFailureSaveMessage('An error occurred during save.')
          return
        }

        this.showSuccessSaveMessage('AIP successfully saved.')
        this.markAsClean()
        setTimeout(() => {
          this.$router.push({ name: 'AdvancedImageProcessing' })
        }, 2000)
      }
    },
    setFileInMemory(file) { this.newUploadedFile = file },
    setSelectedDocument(item) { this.selectedItem = item },
    showFailureSaveMessage(messageDetail) {
      this.snackbarTitle = 'Unable to save'
      this.snackbarSubTitle = messageDetail
      this.snackbarImage = false
      this.showSnackbarBool = true
    },
    showSuccessSaveMessage(messageDetail) {
      this.snackbarTitle = 'Saved'
      this.snackbarSubTitle = messageDetail
      this.snackbarImage = true
      this.showSnackbarBool = true
    },
    staticFieldsUpdated(newStaticFields) {
      this.showStaticFieldsModal = false
      if (newStaticFields == null) {
        return
      }

      this.staticFields = newStaticFields.filter(element => {
        return element.value !== ''
      })
      this.isDirty = true
    },
    toggleRotateZonesForCheck: function () {
      this.isDirty = true
      this.rotateZonesForCheck = !this.rotateZonesForCheck
    },
    toggleRunLookup: function () {
      this.isDirty = true
      this.runLookup = !this.runLookup
    },
    toggleUploadDialog: function () { this.showUploadDialog = !this.showUploadDialog },
    updateSelectedTab(newSelectedTab) {
      this.selectedTabName = newSelectedTab
    },
    validateCompareFields() {
    // eslint-disable-next-line consistent-return
      let validationPassed = true
      this.compareFields.forEach(field => {
        if ((field.compareType.name !== '' && field.value === '') || (field.compareType.name === '' && field.value !== '')) {
          validationPassed = false
        }
      })
      if (!validationPassed) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'Please make sure the compare type and field values are entered for all required compare fields.'
        this.snackbarImage = false
      }
      return validationPassed
    },
    validateSaveData() {
      let emptyField = false
      if (Object.keys(this.selectedForm).length === 0 ||this.selectedForm.value === 0 || this.template === '') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'Please make sure all the required fields are filled in.'
        this.snackbarImage = false
        return false
      }
      if (this.AIPName === '') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Process Title Required'
        this.snackbarSubTitle = 'Please enter a process title.'
        this.snackbarImage = false
        return false
      }
      this.zonalProcesses.forEach(z => {
        if (!z.indexFieldName || z.indexFieldName === '') {
          emptyField = true
        }
      })
      if (emptyField) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'Please select a new field for all zones.'
        this.snackbarImage = false
        return false
      }
      return true
    },
    async viewZones() {
      if (!this.tempTemplateFileServerPath) {
        return
      }

      this.isLoading = true
      const savePayload = this.getSavePayload()

      await this.$store.dispatch('capture/setAipCheckZoneArray', this.processingChecks)
      await this.$store.dispatch('capture/setAipTextZoneArray', this.textAndBarcodeZones)
      await this.$store.dispatch('capture/setAipOmrZoneArray', this.omrZones)
      await this.$store.dispatch('capture/setCurrentAIP', savePayload)
      this.$router.push({ name: 'AipZoneConfiguration' })
      this.isLoading = false
    },
    zonalProcessingType(zone) {
      if (zone?.omrTypeId) {
        return 'OMR'
      } else {
        return zone?.isBarcode ? 'Barcode' : 'Text'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .input-style::v-deep {
    display: inline-block;
    width: 50%;

    input {
      color: black;
      padding-left: 0;
    }
    input::placeholder {
      color: #838587;
    }
  }

  .vasion-html-table-field {
    overflow: visible !important;
  }

  #aip-details {
    width: 100%;

    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 300px;
        }
      }

      .extra-top-margin {
        margin-top: 12px;
      }

    }

    .vasion-html-table-default {
      margin-right:20px;
    }

    .no-items-div {
      margin-top: 30px;
      width: 100%;
      text-align: center;
    }

    .vasion-page-subheader {
      padding-top: 15px;
    }
  }

  .lightestGreyBackground {
    background-color: $grey-75;
  }

  .padding {
    padding: 5px 20px 5px 0px;
  }

  .compare-fields-container {
    padding: 10px;
    width: 50vw;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
  }

  .compare-fields-grid-div {
    padding: 0px 10px 0px 10px;
    height: 450px;
    overflow: auto;
  }

  .align-right {
    display: inline-block;
    text-align: right;
    padding: 10px 10px 10px 0px;
  }

  #upload-new-file-dialog {
    .align-right {
      text-align: right;
      padding: 5px 0px 10px 374px;
    }
  }

  .slight-top-padding {
    padding-top: 2px !important;
  }

</style>
